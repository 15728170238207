

<div class="card">
  <div class="card-header border-0 pb-0">
    <h4 class="mb-0 text-black fs-20">Cumul population touchée Caravane</h4>
  </div>
  <div class="card-body p-3">
    <div class="table-responsive">
      <canvas id="canvas2"></canvas>
    </div>
  </div>
</div>
