
<!--**********************************
	Content body start
***********************************-->
<div class="content-body">
	<div class="container-fluid">
		<div class="row page-titles mx-0">
            <div class="col-sm-6 p-md-0">
                <div class="welcome-text">
                  <h4>Bonjour, bon retour !</h4>
                                 </div>
            </div>
            <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Equipe</a></li>
                    <li class="breadcrumb-item active"><a href="javascript:void(0)">Superviseur</a></li>
                </ol>
            </div>
        </div>
		<div class="row">
			<div class="col-lg-12">
				<div class="card">
					<div class="card-body">
						<div class="table-responsive">
							<table class="table table-responsive-lg mb-0 table-striped" *ngIf="detailsu===1">
								<thead>
									<tr>
										<th  >
											ID
										</th>
										<th>Nom Et Prénom</th>
										<th>Numéro de Télephone</th>
										<th>Role</th>

										<!-- <th>Joined</th> -->
										<th></th>
									</tr>
								</thead>
								<tbody id="customers">
									<tr class="btn-reveal-trigger" *ngFor="let item of users45" >

                    <td class="py-2 pl-5 wspace-no">{{item.id}}</td>
                    <td class="py-2 pl-5 wspace-no" >{{item.name}}</td>

										<td class="py-2"><a  >{{item.phone_number}}</a></td>
										<td class="py-2 pl-5 wspace-no" *ngIf="item.Role == 'superviseurcr'" >Superviseur Caravane </td>
										<td class="py-2 pl-5 wspace-no" *ngIf="item.Role == 'superviseurc'" > Superviseur Comptoire</td>
										<td class="py-2 text-right">
											<div class="dropdown dropdown-no-icon" ngbDropdown (click)="change(item.id)">
												<button class="btn btn-primary tp-btn-light sharp" type="button" ngbDropdownToggle><span class="fs--1">
													<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18px" height="18px" viewBox="0 0 24 24" version="1.1">
													<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg>
													</span>
												</button>
												<div class="dropdown-menu dropdown-menu-right border py-0" ngbDropdownMenu>
													<div class="py-2"><a class="dropdown-item"  href="javascript:void(0);">Edit</a><a class="dropdown-item text-danger" href="javascript:void(0);">Delete</a></div>
												</div>
											</div>
										</td>
									</tr>

								</tbody>
							</table>
              <div class="row" *ngIf="detailsu===2">

                <div class="col-xl-6 col-lg-6">
                  <button (click)="backup()" style="padding: 7px 30px;
                  border: 0px;
                  border-radius: 9px;
                  background: #ffc643;
                  color: white;">Retour</button>

                  <div class="card">
                    <div class="card-header">
                      <h4 class="card-title">Equipe</h4>
                    </div>
                    <div class="card-body">
                      <div class="basic-form">
                        <form *ngFor="let item of equipe">
                          <div class="form-group">
                            <p>Nom :</p>
                            <input class="form-control form-control-lg" type="text" [value]="item.nom" readonly>
                          </div>
                          <div class="form-group">
                            <p>Numéro Télephone:</p>
                            <input class="form-control form-control-lg" type="text" [value]="item.phone" readonly>
                          </div>
                        </form>
                      </div>



                      <!-- <div class="basic-form" *ngFor="let item of equipe" >
                        <h3>Nom : {{item.nom}} - Télephone : {{item.phone}}</h3>
                       </div> -->
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6" style="margin-top: 38px;">
                  <div class="card">
                    <div class="card-header">
                      <h4 class="card-title">Info Voiture</h4>
                    </div>
                    <div class="card-body">
                      <div class="basic-form">
                        <form>
                          <div class="form-group">
                            <p>Immatriculation:</p>
                            <input class="form-control form-control-lg" type="text" [value]="immatriculation" readonly>
                          </div>
                          <div class="form-group">
                            <p>KM:</p>
                            <input class="form-control form-control-lg" type="text" [value]="km" readonly>
                          </div>
                          <div class="form-group">
                            <p>Marque:</p>
                            <input class="form-control form-control-lg" type="text" [value]="marque" readonly>
                          </div>
                          <div class="form-group">
                            <p>Model:</p>
                            <input class="form-control form-control-lg" type="text" [value]="model" readonly>
                          </div>
                        </form>

                      </div>
                    </div>
                  </div>
                </div>

              </div>

						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!--**********************************
	Content body end
***********************************-->
<!-- Button trigger modal -->
<!-- <button type="button" class="btn btn-primary">
  Launch demo modal
</button> -->

<!-- Modal -->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        ...
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div>
