<div class="nav-header">
    <a [routerLink]="['/admin/index']" class="brand-logo">
      <img src="assets/logoshams.jpg" style="width:45%;margin-top:35px;margin-bottom:35px;border-radius: 20px;" alt="">
    </a>

    <div class="nav-control" style="margin-top: 10px;">
        <div [ngClass]="{'is-active': hamburgerClass, '': !hamburgerClass}" class="hamburger" (click)="toggleHamburgerClass()">
            <span class="line"></span><span class="line"></span><span class="line"></span>
        </div>
    </div>
</div>
