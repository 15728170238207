import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Chart, ChartOptions, ChartType } from 'chart.js';

@Component({
  selector: 'app-quick-transfer',
  templateUrl: './quick-transfer.component.html',
  styleUrls: ['./quick-transfer.component.css']
})
export class QuickTransferComponent implements OnInit {
  loading: boolean = false;
  error: string | null = null;

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.fetchKpiData('https://oualis.com/Platforme/api/chart-data?type1=caravane&type2=goudize', 'canvas4', 'Nb des personnes intéressés per Day');
  }

  fetchKpiData(url: string, canvasId: string, title: string): void {
    this.loading = true;

    this.http.get<any[]>(url).subscribe(
      (response) => {
        this.createChart(response, canvasId, title);
        this.loading = false;
      },
      (error) => {
        console.error('Error fetching KPI data:', error);
        this.error = 'Failed to load KPI data';
        this.loading = false;
      }
    );
  }

  createChart(data: any[], canvasId: string, title: string): void {
    const dates = data.map(d => d.date);
    const totalValues = data.map(d => d.total_value);
    const T_shirtValues = data.map(d => d.T_shirt);
    const casquetteValues = data.map(d => d.casquette);
    const sacValues = data.map(d => d.sac);
    const TelValues = data.map(d => d.Tel);

    const ctx = document.getElementById(canvasId) as HTMLCanvasElement;

    if (ctx) {
      new Chart(ctx, {
        type: 'bar' as ChartType,
        data: {
          labels: dates,
          datasets: [
            {
              label: 'Total Value',
              data: totalValues,
              borderColor: '#ffc643',
              backgroundColor: 'rgba(255, 199, 67, 0.2)',
              fill: true
            },
            {
              label: 'T-shirt',
              data: T_shirtValues,
              borderColor: '#42a5f5',
              backgroundColor: 'rgba(66, 165, 245, 0.2)',
              fill: true
            },
            {
              label: 'Casquette',
              data: casquetteValues,
              borderColor: '#66bb6a',
              backgroundColor: 'rgba(102, 187, 106, 0.2)',
              fill: true
            },
            {
              label: 'Sac',
              data: sacValues,
              borderColor: '#ffa726',
              backgroundColor: 'rgba(255, 167, 38, 0.2)',
              fill: true
            },
            {
              label: 'Tel',
              data: TelValues,
              borderColor: '#ab47bc',
              backgroundColor: 'rgba(171, 71, 188, 0.2)',
              fill: true
            }
          ]
        },
        options: {
          responsive: true,
          plugins: {
            title: {
              display: true,
              text: title
            }
          },
          scales: {
            x: {
              display: true,
              title: {
                display: true,
                text: 'Date'
              }
            },
            y: {
              display: true,
              title: {
                display: true,
                text: 'Counts'
              }
            }
          }
        } as ChartOptions
      });
    } else {
      console.error(`Canvas element with id ${canvasId} not found`);
    }
  }
}
