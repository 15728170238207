import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Chart, ChartOptions, ChartType } from 'chart.js';

@Component({
  selector: 'app-buy-order',
  templateUrl: './buy-order.component.html',
  styleUrls: ['./buy-order.component.css']
})
export class BuyOrderComponent implements OnInit {
  loading: boolean = false;
  error: string | null = null;

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.fetchKpiData('https://oualis.com/Platforme/api/chart-data?type1=caravane&type2=interesse', 'canvas', 'Nb des personnes intéressés per Day');
  //   this.fetchKpiData('https://oualis.com/Platforme/api/kpi/kpiNbPersonnestouche', 'canvas2', 'Nb des personnes touché per Day');
  //   this.fetchKpiData('https://oualis.com/Platforme/api/kpi/kpiNbPersonnecontacte', 'canvas3', 'Nb des personnes contacté per Day');
   }

  fetchKpiData(url: string, canvasId: string, title: string): void {
    this.loading = true;

    this.http.get<any[]>(url).subscribe(
      (response) => {
        this.createChart(response, canvasId, title);
        this.loading = false;
      },
      (error) => {
        console.error('Error fetching KPI data:', error);
        this.error = 'Failed to load KPI data';
        this.loading = false;
      }
    );
  }

  createChart(data: any[], canvasId: string, title: string): void {
    const dates = data.map(d => d.date);
    const counts = data.map(d => d.total_value);

    const ctx = document.getElementById(canvasId) as HTMLCanvasElement;

    if (ctx) {
      new Chart(ctx, {
        type: 'bar' as ChartType,
        data: {
          labels: dates,
          datasets: [{
            label: 'Total Value',
            data: counts,
            borderColor: '#ffc643',
            backgroundColor: 'rgba(255, 199, 67, 0.2)',
            fill: true
          }]
        },
        options: {
          responsive: true,
          plugins: {
            title: {
              display: true,
              text: 'title'
            }
          },
          scales: {
            x: {
              display: true,
              title: {
                display: true,
                text: 'Date'
              }
            },
            y: {
              display: true,
              title: {
                display: true,
                text: 'Counts'
              }
            }
          }
        } as ChartOptions
      });
    } else {
      console.error(`Canvas element with id ${canvasId} not found`);
    }
  }
}
