<!--**********************************
	Content body start
***********************************-->
<div class="content-body">
  <div class="container-fluid">
    <!-- Add Order -->
    <div class="row page-titles mx-0">
      <div class="col-sm-6 p-md-0">
        <div class="welcome-text">
          <h4>Bonjour, bon retour !</h4>
        </div>
      </div>
      <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
        <ol class="breadcrumb">
          <li class="breadcrumb-item active"><a href="javascript:void(0)">Oualis</a></li>
        </ol>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="col-xl-12 col-lg-12 col-xxl-12 col-sm-12">
            <div class="card">
              <div class="row" *ngIf="detzils==0">
                <div class="col-lg-4 col-sm-12" style="margin: auto; margin-top: 20px; text-align: center;">
                  <button (click)="status('En Cours')" [ngClass]="{'active-button': statusany === 'En Cours'}" style="padding: 10px 32px; border: 0px; border-radius: 10px; color: white; background: #000;">
                    En Cours
                  </button>
                </div>
                <div class="col-lg-4 col-sm-12" style="margin: auto; margin-top: 20px; text-align: center;">
                  <button (click)="status('pas encore commencé')" [ngClass]="{'active-button': statusany === 'pas encore commencé'}" style="padding: 10px 32px; border: 0px; border-radius: 10px; color: white; background: #000;">
                    Pas encore commencé
                  </button>
                </div>
                <div class="col-lg-4 col-sm-12" style="margin: auto; margin-top: 20px; text-align: center;">
                  <button (click)="status('fin')" [ngClass]="{'active-button': statusany === 'fin'}" style="padding: 10px 32px; border: 0px; border-radius: 10px; color: white; background: #000;">
                    Fin
                  </button>
                </div>
              </div>
<hr>
             <div class="row" style="margin-top:20px" *ngIf="detzils==0">
              <div class="col-4" style="margin:auto;text-align:center">
                <a [ngClass]="{'active-button': userid === 16}" style="padding: 10px 32px;border: 0px;border-radius: 10px;color: white;background: #ffc643;cursor: pointer;"  (click)="statusidu(22,1)" >Caravane 1</a>
              </div>
              <div class="col-4" style="margin:auto;text-align:center">
                <a [ngClass]="{'active-button': userid === 17}" style="padding: 10px 32px;border: 0px;border-radius: 10px;color: white;background: #ffc643;cursor: pointer;"  (click)="statusidu(23,2)" >Caravane 2</a>
              </div>
              <div class="col-4" style="margin:auto;text-align:center">
                <a [ngClass]="{'active-button': userid === 18}" style="padding: 10px 32px;border: 0px;border-radius: 10px;color: white;background: #ffc643;cursor: pointer;"  (click)="statusidu(24,3)" >Caravane 3</a>
              </div>

             </div>
              <div class="card-body" *ngIf="detzils==0">
                <div class="table-responsive recentOrderTable">
                  <table class="table verticle-middle table-responsive-md">
                    <thead>
                      <tr>
                        <th scope="col">ID.</th>
                        <th scope="col">Nom</th>
                        <!-- <th scope="col">Numéro Comptoire</th> -->
                   <th scope="col">N°Caravane</th>
                        <th scope="col">Date de Depart</th>
                        <th scope="col">Status</th>
                        <th scope="col">Date Fin</th>
                        <!-- <th scope="col"></th>
                        <th scope="col">N°Caravane</th>
                        <th scope="col">N°Caravane</th> -->
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody *ngIf="plannings.length > 0">
                      <tr *ngFor="let item of plannings">
                        <td>{{ item.id }}</td>
                        <td>{{ item.souk.nom_du_souk }}</td>
                        <td><span class="badge badge-rounded badge-primary">{{numeroca}}</span></td>
                        <!-- <td>Caravane : {{ item.user.id }}</td> -->
                        <td>{{ item.datedepart }}</td>
                        <td>{{ item.status }}</td>
                        <td>{{ item.datefin }}</td>
                        <td>
                          <div class="dropdown dropdown-no-icon custom-dropdown mb-0" display="dynamic" placement="bottom-right" ngbDropdown>
                            <div class="btn sharp btn-primary tp-btn" ngbDropdownToggle (click)="getdetailplanning(item.id)">
                              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18px" height="18px" viewBox="0 0 24 24" version="1.1">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                  <rect x="0" y="0" width="24" height="24" />
                                  <circle fill="#000000" cx="12" cy="5" r="2" />
                                  <circle fill="#000000" cx="12" cy="12" r="2" />
                                  <circle fill="#000000" cx="12" cy="19" r="2" />
                                </g>
                              </svg>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tbody *ngIf="plannings.length <= 0">
                      <tr>
                        <td colspan="7" class="text-center">Aucune donnée disponible</td>
                      </tr>
                    </tbody>
                  </table>

                </div>
              </div>
              <div class="card-body" *ngIf="detzils==1">
                <div class="row">
                  <div class="col-12">
                    <button style="padding: 9px 32px;
               margin-bottom: 12px;
               border: 0px;
               border-radius: 10px;
               color: white;
               background: #ffc643;" (click)="change()">Retour</button>
                  </div>
                  <!-- <div class="col-12">
                    <h3>Réponse Rapport :</h3>
                    <div class="row"  *ngFor="let item of rrapport">
                      <div class="col-6"><h4>{{item.nom}} : </h4></div>
                      <div class="col-6"><h4>{{item.reponse}}</h4></div>
                    </div>
                  </div> -->
                  <div class="col-12">
                    <h3>List Media : </h3>
                    <div class="row">
                      <div style="text-align:center" class="col-4" *ngFor="let item of users45">
                        <a *ngIf="item.type == 'image'" ><a  > <img style="width: 20vw;
                          height: 20VW;
                          object-fit: cover;border-radius:17px"  [src]="'https://oualis.com/Platforme/public/storage/'+item.filepath" alt=""  ></a></a>
                         <a *ngIf="item.type == 'video'" ><a  > <video style="width: 20vw;
                          height: 20VW;
                          object-fit: cover;border-radius:17px"  controls="true" [src]="'https://oualis.com/Platforme/public/storage/'+item.filepath"></video>

                         </a></a>
                         <a *ngIf="item.type == 'audio'" ><a  >
                          <audio [src]="'https://oualis.com/Platforme/public/storage/'+item.filepath"></audio>

                         </a></a>
                         <br>
                         <div style="text-align: center;margin-top:10px;margin-bottom:10px">
                          <button (click)="downloadFile(item.id)" style="padding: 10px;
                          background-color: #ffc643;
                          color: white;
                          border: none;
                          border-radius: 5px;
                          width: 77%;">Download</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="col-lg-12 col-sm-12">
                    <div class="row">
                      <div class="col-12">
                        <h3>Info Planning :</h3>
                        <div class="row">
                          <div class="col-6">
                            <h5>Départ Phase 1 KM : <span>{{planningsStock.phase1kms}}</span></h5>
                            <h5>Fin Phase 1 KM : <span>{{planningsStock.phase1kmr}}</span></h5>
                            <h5>Image Phase 1 KM : <span><img
                                  [src]="'https://oualis.com/Platforme/public/storage/'+planningsStock.phase1kmp"
                                  style="width: 100%;" alt=""></span></h5>
                            <h5>Lien Map Phase 1 : <span> <a
                                  [href]="planningsStock.phase1linkmap">{{planningsStock.phase1linkmap}}</a></span></h5>

                          </div>
                          <div class="col-6">
                            <h5>Départ Souk KM : <span>{{planningsStock.soukkms}}</span></h5>
                            <h5>Fin Souk KM : <span>{{planningsStock.soukkmr}}</span></h5>
                            <h5>Image KM : <span><img
                                  [src]="'https://oualis.com/Platforme/public/storage/'+planningsStock.soukkmp"
                                  style="width: 100%;" alt=""></span></h5>

                          </div>
                          <div class="col-6">
                            <h3>Réponse Rapport :</h3>
                            <div class="col-lg-4 col-sm-6" *ngFor="let item of rrapport">
                              <h4>{{item.nom}} : {{item.reponse}}</h4>
                            </div>
                          </div>

                          <div class="col-6">
                            <h3>Réponse Souk :</h3>
                            <div class="col-lg-4 col-sm-6" *ngFor="let item of rsouk">
                              <h4>{{item.nom}} : {{item.reponse}}</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> -->

                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /# card -->
      </div>
    </div>
  </div>
</div>
<!--**********************************
	Content body end
***********************************-->
