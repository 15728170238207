<div class="card overflow-hidden">
    <div class="card-header align-items-start border-0 pb-0">
        <div class="mr-auto">
            <p class="mb-2 fs-13"><i class="fa fa-caret-down scale5 mr-2 text-success" aria-hidden="true"></i>NB Activation En Cours :</p>
            <h2 class="text-black mb-0 font-w600">{{soukcount}}</h2>
        </div>

    </div>
    <div class="chart-wrapper">

        <!-- <canvas id="widgetChart2" class="max-h80 mt-auto"></canvas> -->
    </div>
</div>
