<div class="card">
  <div class="card-header border-0 pb-0">
    <h4 class="mb-0 text-black fs-20">nbr des personnes briffer par l'animateur /ice (Comptoire)</h4>
  </div>
  <div class="card-body p-3">
    <div class="table-responsive">
      <canvas id="canvas6"></canvas>
    </div>
  </div>
</div>
<!--
<br>

<div class="card">
  <div class="card-header border-0 pb-0">
    <h4 class="mb-0 text-black fs-20">Cumul population touchée</h4>
  </div>
  <div class="card-body p-3">
    <div class="table-responsive">
      <canvas id="canvas2"></canvas>
    </div>
  </div>
</div>

<br>

<div class="card">
  <div class="card-header border-0 pb-0">
    <h4 class="mb-0 text-black fs-20">Cumul population contacté</h4>
  </div>
  <div class="card-body p-3">
    <div class="table-responsive">
      <canvas id="canvas3"></canvas>
    </div>
  </div>
</div> -->
