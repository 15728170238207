<div class="deznav">
    <div class="deznav-scroll" [perfectScrollbar]>
        <ul class="metismenu" id="menu" metis-menu>
            <li [ngClass]="{'mm-active': dashboardArray.indexOf(currentHref) > -1}"><a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
                <i class="flaticon-381-networking"></i>
                <span class="nav-text">Dashboard</span>
                </a>
            <ul aria-expanded="false"  [ngClass]="{'mm-show': dashboardArray.indexOf(currentHref) > -1}">
                <li><a  class="{{(currentHref == '/admin/index' || currentHref == '/admin') ? 'mm-active' : ''}}" [routerLink]="['/admin/index']">Accueil</a></li>
            </ul>

            </li>
            <li [ngClass]="{'mm-active': apsArray.indexOf(currentHref) > -1}">
                <a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
                    <i class="flaticon-381-television"></i>
                    <span class="nav-text">Gestion des Activation</span>
                </a>
                <ul aria-expanded="false" [ngClass]="{'mm-show': apsArray.indexOf(currentHref) > -1}">
                    <!-- <li><a [routerLink]="['/admin/app-profile']" class="{{(currentHref == '/admin/app-profile') ? 'mm-active' : ''}}">Ajouter Superviseur</a></li>
                    <li><a [routerLink]="['/admin/post-details']" class="{{(currentHref == '/admin/post-details') ? 'mm-active' : ''}}">List Des Superviseur</a></li> -->
                    <li [ngClass]="{'mm-active': apsArray.indexOf(currentHref) > 1 && apsArray.indexOf(currentHref) < 5}"><a class="has-arrow" href="javascript:void()" aria-expanded="false">Comptoire</a>
                      <ul aria-expanded="false" [ngClass]="{'mm-show': apsArray.indexOf(currentHref) > 1 && apsArray.indexOf(currentHref) < 5}">
                          <!-- <li><a [routerLink]="['/admin/ecom-checkout']" class="{{(currentHref == '/admin/ecom-checkout') ? 'mm-active' : ''}}">Ajouter Superviseur</a></li> -->
                          <li><a [routerLink]="['/admin/List-Comptoire']" class="{{(currentHref == '/admin/List-Comptoire') ? 'mm-active' : ''}}">List Des Comptoire</a></li>
                          <!-- <li><a [routerLink]="['/admin/email-read']" class="{{(currentHref == '/admin/email-read') ? 'mm-active' : ''}}">Read</a></li> -->
                      </ul>
                  </li>
                  <li [ngClass]="{'mm-active': apsArray.indexOf(currentHref) > 1 && apsArray.indexOf(currentHref) < 5}"><a class="has-arrow" href="javascript:void()" aria-expanded="false">Caravane</a>
                    <ul aria-expanded="false" [ngClass]="{'mm-show': apsArray.indexOf(currentHref) > 1 && apsArray.indexOf(currentHref) < 5}">
                        <!-- <li><a [routerLink]="['/admin/email-compose']" class="{{(currentHref == '/admin/email-compose') ? 'mm-active' : ''}}">Ajouter Equipe</a></li> -->
                        <li><a [routerLink]="['/admin/List-Caravane']" class="{{(currentHref == '/admin/List-Caravane') ? 'mm-active' : ''}}">List Des Caravane</a></li>
                        <!-- <li><a [routerLink]="['/admin/email-read']" class="{{(currentHref == '/admin/email-read') ? 'mm-active' : ''}}">Read</a></li> -->
                    </ul>
                </li>

                </ul>
            </li>
<!--
            <li [ngClass]="{'mm-active': chartsArray.indexOf(currentHref) > -1}">
                <a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
                    <i class="flaticon-381-controls-3"></i>
                    <span class="nav-text">Gestion Les Souk</span>
                </a>
                <ul aria-expanded="false" [ngClass]="{'mm-show': chartsArray.indexOf(currentHref) > -1}">

                    <li><a [routerLink]="['/admin/chart-chartjs']" class="{{(currentHref == '/admin/chart-chartjs') ? 'mm-active' : ''}}">List des Souk</a></li>
                    <li><a [routerLink]="['/admin/apex-bubble']" class="{{(currentHref == '/admin/apex-bubble') ? 'mm-active' : ''}}">Ajouter Souk A Superviseur</a></li>
                </ul>
            </li>

            <li [ngClass]="{'mm-active': bootstrapArray.indexOf(currentHref) > -1}">
                <a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
                    <i class="flaticon-381-internet"></i>
                    <span class="nav-text">Gestion les Voitures</span>
                </a>
                <ul aria-expanded="false" [ngClass]="{'mm-show': bootstrapArray.indexOf(currentHref) > -1}">
                    <li><a [routerLink]="['/admin/ui-accordion']" class="{{(currentHref == '/admin/ui-accordion') ? 'mm-active' : ''}}">Ajouter Voiture</a></li>
                    <li><a [routerLink]="['/admin/ui-alert']" class="{{(currentHref == '/admin/ui-alert') ? 'mm-active' : ''}}">List des Voiture</a></li>

                </ul>
            </li>

            <li [ngClass]="{'mm-active': materialArray.indexOf(currentHref) > -1}">
                <a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
                    <i class="flaticon-381-layer-1"></i>
                    <span class="nav-text">Gestion Stock</span>
                </a>
                <ul aria-expanded="false" [ngClass]="{'mm-show': materialArray.indexOf(currentHref) > -1}">
                    <li><a [routerLink]="['/admin/mat-autocomplete']" class="{{(currentHref == '/admin/mat-autocomplete') ? 'mm-active':''}}">Ajouter Stock</a></li>
                    <li><a [routerLink]="['/admin/mat-badge']" class="{{(currentHref == '/admin/mat-badge') ? 'mm-active' : ''}}">List Stock</a></li>
                    <li><a [routerLink]="['/admin/mat-bottom-sheet']" class="{{(currentHref == '/admin/mat-bottom-sheet') ? 'mm-active' : ''}}">Details Stock</a></li>


                </ul>
            </li>

            <li [ngClass]="{'mm-active': pluginsArray.indexOf(currentHref) > -1}">
                <a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
                    <i class="flaticon-381-heart"></i>
                    <span class="nav-text">Gestion Media</span>
                </a>
                <ul aria-expanded="false" [ngClass]="{'mm-show': pluginsArray.indexOf(currentHref) > -1}">
                    <li><a [routerLink]="['/admin/uc-nestable']" class="{{(currentHref == '/admin/uc-nestable') ? 'mm-active' : ''}}">Media</a></li>
                 </ul>
            </li>
            <li class="{{(currentHref == '/admin/uc-lightgallery') ? 'mm-active' : ''}}">
                <a [routerLink]="['/admin/uc-lightgallery']" class="ai-icon {{(currentHref == '/admin/uc-lightgallery') ? 'mm-active' : ''}}" aria-expanded="false">
                    <i class="flaticon-381-settings-2"></i>
                    <span class="nav-text">Gestion les Souk</span>
                </a>
                <ul aria-expanded="false" [ngClass]="{'mm-show': pluginsArray.indexOf(currentHref) > -1}">
                   <li><a [routerLink]="['/admin/uc-lightgallery']" class="{{(currentHref == '/admin/uc-lightgallery') ? 'mm-active' : ''}}">Gestion les Souk</a></li>
              </ul>
            </li>

         <li [ngClass]="{'mm-active': formsArray.indexOf(currentHref) > -1}">
                <a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
                    <i class="flaticon-381-notepad"></i>
                    <span class="nav-text">Questionne</span>
                </a>
                <ul aria-expanded="false" [ngClass]="{'mm-show': formsArray.indexOf(currentHref) > -1}">
                    <li><a [routerLink]="['/admin/form-element']" class="{{(currentHref == '/admin/form-element') ? 'mm-active' : ''}}">Génerale</a></li>
                    <li><a [routerLink]="['/admin/form-validate']" class="{{(currentHref == '/admin/form-validate') ? 'mm-active' : ''}}"> Rapport</a></li>
                </ul>
            </li> -->

        </ul>


    </div>
</div>
