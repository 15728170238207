import { Component, OnInit, ViewChild,ElementRef } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';
 import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef } from '@angular/core';
@Component({
  selector: 'app-light-gallery',
  templateUrl: './light-gallery.component.html',
  styleUrls: ['./light-gallery.component.css']
})
export class LightGalleryComponent implements OnInit {
  statusany:any;

  userid:any;







  status(status: any): void {

    this.statusany = status;
    this.getdata();
  }
numeroca:any;
  statusidu(id: any,numca:any): void {
  this.numeroca = numca;
    this.userid = id;
    this.getdata();
  }

  getdata(): void {
    this.http.get<any>(`https://oualis.com/Platforme/api/soukplannings/${this.userid}/status?status=${this.statusany}`).subscribe(
      (response) => {
        this.plannings = response;

      },
      (error) => {
        console.error('Error fetching souk count:', error);
      }
    );
  }
detailpla:any;
 getdetailplanning(id:any){
  this.http.get<any>(`https://oualis.com/Platforme/api/soukplanning/`+id).subscribe(
    (response) => {
      this.detzils = 1;
      this.detailpla = response;
      this.soukid = response.souk.id;
      // alert(this.soukid);
      this.fetchUsers();
    },
    (error) => {
      console.error('Error fetching souk count:', error);
    }
  );
}

users45:any;
soukid:any;
fetchUsers(): void {
  this.http.get<any[]>('https://oualis.com/Platforme/api/media/souk/'+this.soukid).subscribe(
   (response) => {
     this.users45 = response;

      console.log('Users:', this.users45); // Logging to verify data in console
   },
   (error) => {
    //  this.error = 'Error fetching users. Please try again.'; // Set error message
      console.error('Error fetching users:', error);
   }
 );


 this.http.get<any>(`https://oualis.com/Platforme/api/rrapport/`+this.soukid).subscribe(
  (response) => {
    this.rrapport = response;
   },
  (error) => {
    console.error('Error fetching souk count:', error);
  }
);
}




  constructor(private http: HttpClient) {}


  plannings:any;

  ngOnInit(): void {
    // this.http.get<any>(`https://oualis.com/Platforme/api/soukplanning`).subscribe(
    //   (response) => {
    //     this.plannings = response;
    //    },
    //   (error) => {
    //     console.error('Error fetching souk count:', error);
    //   }
    // );
  }
  getbystatus(status:any){
    this.http.get<any>('https://oualis.com/Platforme/api/plannings/status/'+status).subscribe(
      (response) => {
        this.plannings = response;
       },
      (error) => {
        console.error('Error fetching souk count:', error);
      }
    );
  }
detzils:any=0;
planningsStock:any;
rrapport:any;
rsouk:any;
change(idsouk:any,id:any){
  this.http.get<any>(`https://oualis.com/Platforme/api/soukplanning/`+id).subscribe(
    (response) => {
      this.planningsStock = response;
     },
    (error) => {
      console.error('Error fetching souk count:', error);
    }
  );

  this.http.get<any>(`https://oualis.com/Platforme/api/rrapport/`+idsouk).subscribe(
    (response) => {
      this.rrapport = response;
     },
    (error) => {
      console.error('Error fetching souk count:', error);
    }
  );

  this.http.get<any>(`https://oualis.com/Platforme/api/rsouk/`+idsouk).subscribe(
    (response) => {
      this.rsouk = response;
     },
    (error) => {
      console.error('Error fetching souk count:', error);
    }
  );


  if(this.detzils == 0)
    {
      this.detzils = 1;
      this.getsouck(idsouk);
      this.getTaches(idsouk);
    }
    else{
      this.detzils = 0;
    }
}
soukNote:any;
getsouck(idsouk:any){
  this.http.get<any>(`https://oualis.com/Platforme/api/notes/souk/`+idsouk).subscribe(
    (response) => {
      this.soukNote = response;
     },
    (error) => {
      console.error('Error fetching souk count:', error);
    }
  );

}

soukTaches:any;
getTaches(idsouk:any){
  this.http.get<any>(`https://oualis.com/Platforme/api/checklists/souk/`+idsouk).subscribe(
    (response) => {
      this.soukTaches = response;
     },
    (error) => {
      console.error('Error fetching souk count:', error);
    }
  );

}



downloadFile(id: number) {
  const url = `https://oualis.com/Platforme/api/media/download/${id}`;
  this.http.get(url, { responseType: 'blob' }).subscribe({
    next: (blob) => {
      const a = document.createElement('a');
      const objectUrl = URL.createObjectURL(blob);
      a.href = objectUrl;
      a.download = 'file'; // Set the filename
      a.click();
      URL.revokeObjectURL(objectUrl);
    },
    error: (err) => {
      console.error('Download failed:', err);
      if (err.status === 0) {
        alert('Download failed due to network or CORS issues. Please try again later.');
      } else {
        alert(`Download failed with status: ${err.status} - ${err.statusText}`);
      }
    }
  });
}




}
